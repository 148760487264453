import accounts from '@/api/repos/accounts/accounts'
import finoSessions from '@/api/repos/accounts/finoSessions'
import nordigenInstitutions from '@/api/repos/accounts/nordigenInstitutions'
import nordigenRequisitions from '@/api/repos/accounts/nordigenRequisitions'
import transactionImports from '@/api/repos/accounts/transactionImports'
import sync from '@/api/repos/accounts/sync'
import transactions from '@/api/repos/accounts/transactions'
import annuities from '@/api/repos/annuities/annuities'
import annuityFees from '@/api/repos/annuities/annuityFees'
import personAvailableAnnuities from '@/api/repos/annuities/personAvailableAnnuities'
import assignments from '@/api/repos/assignments/assignments'
import impersonations from '@/api/repos/auth/impersonations'
import additionalCertificatesPersons from '~/api/repos/billing/additionalCertificatesPersons'
import enrolments from '@/api/repos/billing/enrolments'
import invoices from '@/api/repos/billing/invoices'
import paymentMethod from '@/api/repos/billing/paymentMethod'
import pools from '@/api/repos/billing/pools'
import stripePrices from '@/api/repos/billing/stripePrices'
import subscription from '@/api/repos/billing/subscription'
import trialExtensions from '@/api/repos/billing/trialExtensions'
import announcements from '@/api/repos/bulletin/announcements'
import dismissals from '@/api/repos/bulletin/dismissals'
import availableLists from '@/api/repos/certificates/availableLists'
import certificates from '@/api/repos/certificates/certificates'
import exemptInspections from '@/api/repos/certificates/exemptInspections'
import exemptionPdfPreviews from '@/api/repos/certificates/exemptionPdfPreviews'
import inspectionActions from '@/api/repos/certificates/inspectionActions'
import inspections from '@/api/repos/certificates/inspections'
import obligations from '@/api/repos/certificates/obligations'
import deletions from '@/api/repos/deletions/deletions'
import salesMode from '@/api/repos/demoWorkspaces/salesMode'
import ephemeralWorkspaces from '@/api/repos/demoWorkspaces/ephemeralWorkspaces'
import disbursers from '@/api/repos/disbursers/disbursers'
import dsgvoExports from '@/api/repos/dsgvoExports/dsgvoExports'
import edeka from '@/api/repos/edeka/edeka'
import currencies from '@/api/repos/exchangeRates/currencies'
import categories from '@/api/repos/expenses/categories'
import costunits from '@/api/repos/expenses/costunits'
import expenses from '@/api/repos/expenses/expenses'
import feeActions from '@/api/repos/fees/feeActions'
import fees from '@/api/repos/fees/fees'
import financeReports from '@/api/repos/financeReports/financeReports'
import blocks from '@/api/repos/forms/blocks'
import blockTypes from '@/api/repos/forms/blockTypes'
import discounts from '@/api/repos/forms/discounts'
import forms from '@/api/repos/forms/forms'
import prices from '@/api/repos/forms/prices'
import divisions from '@/api/repos/hierarchy/divisions'
import invites from '@/api/repos/hierarchy/invites'
import columns from '@/api/repos/listColumns/columns'
import listImports from '@/api/repos/listImports/listImports'
import duplicates from '@/api/repos/lists/duplicates'
import familyMembers from '@/api/repos/lists/familyMembers'
import filterPresets from '@/api/repos/lists/filterPresets'
import glances from '@/api/repos/lists/glances'
import groups from '@/api/repos/lists/groups'
import labels from '@/api/repos/lists/labels'
import lists from '@/api/repos/lists/lists'
import personActions from '@/api/repos/lists/personActions'
import persons from '@/api/repos/lists/persons'
import triggers from '@/api/repos/messageTriggers/triggers'
import triggerPreviews from '@/api/repos/messageTriggers/triggerPreviews'
import triggerRetries from '@/api/repos/messageTriggers/triggerRetries'
import messagePreviews from '@/api/repos/messaging/messagePreviews'
import messages from '@/api/repos/messaging/messages'
import recipients from '@/api/repos/messaging/recipients'
import bulletin from '@/api/repos/misc/bulletin'
import numberBlocks from '@/api/repos/numberSequences/numberBlocks'
import financeOnboarding from '@/api/repos/workspaceSettings/financeOnboarding'
import settings from '@/api/repos/workspaceSettings/settings'
import countries from '@/api/repos/lookups/countries'
import postcodes from '@/api/repos/lookups/postcodes'
import ibans from '@/api/repos/lookups/ibans'
import memberProvider from '@/api/repos/memberProviders/memberProvider'
import memberProviderActivation from '@/api/repos/memberProviders/memberProviderActivation'
import memberReports from '@/api/repos/memberReports/memberReports'
import pagePreviews from '@/api/repos/receipts/pagePreviews'
import receipts from '@/api/repos/receipts/receipts'
import thumbnails from '@/api/repos/receipts/thumbnails'
import recoveries from '@/api/repos/recoveries/recoveries'
import registrations from '@/api/repos/registrations/registrations'
import sepaChecks from '@/api/repos/sepa/sepaChecks'
import sepaDisputes from '@/api/repos/sepa/sepaDisputes'
import sepaJobs from '@/api/repos/sepa/sepaJobs'
import sepaJobXmls from '@/api/repos/sepa/sepaJobXmls'
import taxSituation from '@/api/repos/tax/taxSituation'
import taxThresholds from '@/api/repos/tax/taxThresholds'
import teamtoolSessions from '@/api/repos/teamtool/teamtoolSessions'
import uploads from '@/api/repos/uploads/uploads'
import users from '@/api/repos/users/users'
import apiToken from '@/api/repos/publicApi/apiToken'

export default (ctx, inject) => {
  const axios = ctx.$axios

  inject('api', {
    accounts: accounts(axios),
    finoSessions: finoSessions(axios),
    nordigenRequisitions: nordigenRequisitions(axios),
    nordigenInstitutions: nordigenInstitutions(axios),
    transactionImports: transactionImports(axios),
    sync: sync(axios),
    transactions: transactions(axios),
    annuities: annuities(axios),
    annuityFees: annuityFees(axios),
    personAvailableAnnuities: personAvailableAnnuities(axios),
    assignments: assignments(axios),
    impersonations: impersonations(axios),
    additionalCertificatesPersons: additionalCertificatesPersons(axios),
    enrolments: enrolments(axios),
    invoices: invoices(axios),
    paymentMethod: paymentMethod(axios),
    pools: pools(axios),
    stripePrices: stripePrices(axios),
    subscription: subscription(axios),
    trialExtensions: trialExtensions(axios),
    announcements: announcements(axios),
    dismissals: dismissals(axios),
    availableLists: availableLists(axios),
    certificates: certificates(axios),
    exemptInspections: exemptInspections(axios),
    exemptionPdfPreviews: exemptionPdfPreviews(axios),
    inspectionActions: inspectionActions(axios),
    inspections: inspections(axios),
    obligations: obligations(axios),
    deletions: deletions(axios),
    salesMode: salesMode(axios),
    ephemeralWorkspaces: ephemeralWorkspaces(axios),
    disbursers: disbursers(axios),
    dsgvoExports: dsgvoExports(axios),
    edeka: edeka(axios),
    currencies: currencies(axios),
    categories: categories(axios),
    costunits: costunits(axios),
    expenses: expenses(axios),
    feeActions: feeActions(axios),
    fees: fees(axios),
    financeReports: financeReports(axios),
    blocks: blocks(axios),
    blockTypes: blockTypes(axios),
    discounts: discounts(axios),
    forms: forms(axios),
    prices: prices(axios),
    divisions: divisions(axios),
    invites: invites(axios),
    columns: columns(axios),
    listImports: listImports(axios),
    duplicates: duplicates(axios),
    familyMembers: familyMembers(axios),
    filterPresets: filterPresets(axios),
    glances: glances(axios),
    groups: groups(axios),
    labels: labels(axios),
    lists: lists(axios),
    personActions: personActions(axios),
    persons: persons(axios),
    triggers: triggers(axios),
    triggerPreviews: triggerPreviews(axios),
    triggerRetries: triggerRetries(axios),
    messagePreviews: messagePreviews(axios),
    messages: messages(axios),
    recipients: recipients(axios),
    bulletin: bulletin(axios),
    numberBlocks: numberBlocks(axios),
    financeOnboarding: financeOnboarding(axios),
    settings: settings(axios),
    apiToken: apiToken(axios),
    countries: countries(axios),
    postcodes: postcodes(axios),
    ibans: ibans(axios),
    memberProvider: memberProvider(axios),
    memberProviderActivation: memberProviderActivation(axios),
    memberReports: memberReports(axios),
    pagePreviews: pagePreviews(axios),
    receipts: receipts(axios),
    thumbnails: thumbnails(axios),
    recoveries: recoveries(axios),
    registrations: registrations(axios),
    sepaChecks: sepaChecks(axios),
    sepaDisputes: sepaDisputes(axios),
    sepaJobs: sepaJobs(axios),
    sepaJobXmls: sepaJobXmls(axios),
    taxSituation: taxSituation(axios),
    taxThresholds: taxThresholds(axios),
    teamtoolSessions: teamtoolSessions(axios),
    uploads: uploads(axios),
    users: users(axios)
  })
}
