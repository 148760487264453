//
//
//
//
//
//


import AuthFooter from '@/components/next/auth/AuthFooter.vue'

export default {
  components: { AuthFooter },
  head: {
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
}
